import { z } from 'zod'

export const CompanyStatusSchema = z.enum(['preparing', 'active', 'inactive'], {
  message: '利用状況が無効な値です',
})
export type CompanyStatus = z.infer<typeof CompanyStatusSchema>

export const DefaultCompanyStatus: "preparing" | "active" | "inactive" = CompanyStatusSchema.enum.active

export const CompanyStatusLabels: { [key: string]: string } = {
  preparing: '準備中',
  active: '利用中',
  inactive: '利用終了',
}

export const CompanySchema = z.object({
  id: z.number(),
  code: z.string(),
  name: z
    .string()
    .min(1, { message: '企業名は必須です' })
    .max(255, { message: '最大255以下の文字数で登録してください' }),
  industry_id: z
    .number({ message: '業種は必須です' })
    .positive({ message: '業種は必須です' }),
  kana: z
    .string()
    .regex(/^[ぁ-んー]+$/u, { message: 'ひらがなで入力してください' })
    .min(1, { message: '企業名（かな）は必須です' })
    .max(255, { message: '最大255以下の文字数で登録してください' }),
  min_display: z
    .number({ message: '表示最小数は必須です' })
    .min(2, { message: '表示最小数は2以上で入力してください' }),
  memo: z
    .string()
    .max(4000, { message: '最大4000以下の文字数で登録してください' })
    .nullish(),
  status: CompanyStatusSchema,
  deliver_survey_mail_text: z
    .string()
    .trim()
    .min(1, { message: 'サーベイ配信開始通知メール 冒頭文は必須です' })
    .max(1000, { message: '最大1000以下の文字数で登録してください' }),
  remind_survey_mail_text: z
    .string()
    .trim()
    .min(1, { message: 'サーベイ終了2日前リマインドメール 冒頭文は必須です' })
    .max(1000, { message: '最大1000以下の文字数で登録してください' }),
  created_at: z.string().optional(),
  survey_count: z.number().optional(),
  progress_survey_count: z.number().optional(),
})

export type Company = z.infer<typeof CompanySchema>
